import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"

const ContactPage = () => (
  <Layout>
    <Head title="Contact" />
    <h1>Contact</h1>
    <h3>Contact us by eduard.amarevitam@gmail.com</h3>
    <h3>Contact us by +385 91 505 1471</h3>
  </Layout>
)

export default ContactPage
